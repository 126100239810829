



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/poppins/Poppins-Regular.ttf'); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/poppins/Poppins-Bold.ttf'); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../fonts/poppins/Poppins-Medium.ttf'); 
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/montserrat/Montserrat-Bold.ttf'); 
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
}

body, html {
	height: 100%;
	font-family: Poppins-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
	font-family: Poppins-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
  color: #57b846;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-family: Poppins-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}
.logo{
  width: 400px;
}
.layoutPainel-Options{
  margin-top: -5px !important;
  background-color: transparent !important;
}
.divlogoMini{
  z-index: 900;
  margin-left: -8px;
  height: 64px;
}
.logoMini{
  width: 250px;
}
.divlogo{
  margin-top: -150px;
  max-width: 500px;
}
/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.5;
  color: #999999;
}
.SelectedConversation{
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  min-width: 100px;
  min-height: 100px;
}
.SelectedConversationTextPlataformsDIv{
  color: #6d6b6b;
  font-size: 14px;
}
.SelectedConversationTextTitleDIv{
  color: #6d6b6b;
  font-size: 22px;
  font-weight: bold;
} 

.txt2 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.5;
  color: #666666;
}
.Card{
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  background-color: #f0f2f5 !important;
}
.layoutPainel-Scroll{
  padding-bottom: 80px;
}
.inputForm {
  width: 100%;
  margin-bottom: 15px;
  padding-left: 12px;
  border-radius: 1px;
  border: 2px solid #ddd;
  font-size: 15px;
  height: 40px;
  color: #444;
  transition: border-color 0.2s;
}
.timer{
  color: #f0f2f5 !important;
  float: right;
  right: 10px;
}
.blockday{
  background-color: #c8c8c8;
}
.inputForm:focus {
  border-color: #111;
}
.inputForm:disabled {
  cursor:not-allowed;
}
.ScrollClient{
  height: calc(100vh - 80px) !important;
}
.layoutPainel{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.ant-menu-sub{
  background: #9053c7 !important;
}
.layoutPainel-Menu{
  background: #4158d0 !important;
  background: -webkit-linear-gradient(-135deg, #4158d0, #c850c0) !important ;
  background: -o-linear-gradient(-135deg, #4158d0, #c850c0) !important ;
  background: -moz-linear-gradient(-135deg, #4158d0, #c850c0) !important;
  background: linear-gradient(-135deg, #4158d0, #c850c0) !important;
}
.layoutPainel-Header{
  z-index: 1000;
  background: #9053c7 !important;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0) !important ;
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0) !important ;
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0) !important;
  background: linear-gradient(-135deg, #c850c0, #4158d0) !important;
  padding: 0px;
}
.Messages_flutuantEmojis{
  position: fixed;
  margin-bottom: 130px;
  bottom: 0px;
  margin-left: 10px;
  float: left;
}
.Messages_footer{
  width: 100%;
  display: flex;
  transition: width 0.2s;
  background-color:transparent;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Messages_DivInput{
  position: relative;
  width: 100%;
  flex-direction: row;
  bottom: 40px;
}
.Messages_bubblearrowalt {
  bottom: 20px;
  left: auto;
  right: 4px;
  float: right;
  position: absolute;
}
.Messages_bubblearrowalt:after {
  border-top: 15px solid #fff;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  content: "";
  position: absolute;
  border-radius: 4px 0px 0px 0px;
  border-left: 15px solid transparent;
  width: 0;
  height: 0;
}
.Messages_DivSend{
  position: relative;
  margin-left: 20px;
  bottom: 28px;
}
.Messages_input {
  width: 100%;
  border-radius: 10px;
  font-size: 15px;
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  height: 50px;
  border-color: transparent;
  border-style: none;
}
.Messages_Emojis {
  position:absolute;
  bottom:-38px;
  left:20px;
  width:24px;
  height:24px;
}
.Messages_Send{
  background-color: #fff !important;
  width: 50px;
  height: 50px;
}
.Messages_Content{
  padding-bottom: 0px;
  background-image: url("../public/bg.jpg");
  height: 100%;
}
.Support_Conversations_Footer{
  background-image: url("../public/bg.jpg") !important;
  height: 66px;
}

.Messsage_Item_msg {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
}
.Messsage_Item_txt {
  padding: 8px 0 8px 0;
  width: 100%;
}
.Messsage_Item_timestamp {
  font-size: 11px;
  margin: auto;
  padding: 0 15px 0 0;
  display: table;
  float: right;
  position: relative;
  color: #999;
  margin-top: 3px;
  margin-left: 5px;
}
.Messsage_Item_message {
  font-size: 14px;
  font-weight: 500;
  padding: 0 15px 0 15px;
  margin: auto;
  color: #2b2b2b;
  display: table;
  /*word-break: break-all;
  white-space: pre-wrap;     
  white-space: -moz-pre-wrap;  
  white-space: -pre-wrap;  
  white-space: -o-pre-wrap;  
  word-wrap: break-word; */ 
}
.ant-avatar img {
  min-height: 50px;
  min-width: 50px;
  border-radius: inherit !important;
}

.Messsage_Item_bubble_1 {
  margin: 10px 25px 3px 0px;
  background: #DCF8C6;
  float: right;
  max-width: 75%;
  width: auto;
  height: auto;
  display: block;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}
.Messsage_Item_name_1 {
  font-weight: 600;
  font-size: 14px;
  display: inline-table;
  padding: 0 0 0 15px;
  margin: 0 0 4px 0;
  color: #3498db;
}
.Messsage_Item_name_1 span {
  font-weight: normal;
  color: #b3b3b3;
  overflow: hidden;
}
.Messsage_Item_bubble_0 {
  float: left;
  max-width: 75%;
  width: auto;
  height: auto;
  display: block;
  background: #fff;
  border-radius: 5px;
  position: relative;
  margin: 10px 0 3px 25px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}
.Messsage_Item_name_0 span {
  font-weight: normal;
  color: #b3b3b3;
  overflow: hidden;
}
.Messsage_Item_name_0 {
  font-weight: 600;
  font-size: 14px;
  display: inline-table;
  padding: 0 0 0 15px;
  margin: 0 0 4px 0;
  color: #2ecc51;
}
.Messsage_Item_bubblearrow_1 {
  bottom: 20px;
  left: auto;
  right: 4px;
  float: right;
  position: absolute;
}
.Messsage_Item_bubblearrow_1:after {
  border-top: 15px solid #DCF8C6;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  content: "";
  position: absolute;
  border-radius: 4px 0px 0px 0px;
  border-left: 15px solid transparent;
  width: 0;
  height: 0;
}
.Messsage_Item_bubblearrow_0 {
  bottom: 20px;
  left: -11px;
  float: left;
  position: absolute;
}
.Messsage_Item_bubblearrow_0:after {
  content: "";
  position: absolute;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  border-top: 15px solid #fff;
  border-left: 15px solid transparent;
  border-radius: 4px 0 0 0px;
  width: 0;
  height: 0;
}
/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}
.formLogin{
  width: 100%;
  height: 100%;
}
.wrap-login100 {
  margin-top: -50px;
  min-width: 460px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 50px 50px 50px;
}

/*------------------------------------------------------------------
[  ]*/
.login100-pic {
  width: 316px;
}

.login100-pic img {
  max-width: 100%;
}


/*------------------------------------------------------------------
[  ]*/
.login100-form {
  width: 290px;
}

.login100-form-title {
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 54px;
}


/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  font-family: Poppins-Medium;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;

  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}


/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87,184,70, 0.8);
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #57b846;
  padding-left: 28px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #57b846;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}
.Support_Conversations_row:hover{
  background-color: rgb(216, 215, 215);
}
.Support_Conversations_row{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 70;
  border-bottom-color: #e6e5e5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  padding: 10px;
}
.Support_Conversations{
  width: 300px !important;
  background-color: #f7f3f3! important;
  height: calc(100vh - 130px);
}

.Support_Conversations_Header{
  background-color: #e9e9e9! important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Support_Conversations_Client{
  background-color: #fff !important;
}
.Support_Conversations_Client_Infos{
  padding: 10px;
}
.Support_Conversations_Client_Header{
  background-color: #e9e9e9! important;
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Support_Conversations_Client_Button{
  width: 100%;
  height: 60px;
  background-color: #3498db;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  color: white;
}
.todoContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px) !important;
}
.todo-row {
  background: linear-gradient(
      90deg,
      rgba(93, 12, 255, 1) 0%,
      rgba(155, 0, 250, 1) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px auto;
  color: #fff;
  padding: 16px;
  border-radius: 5px;
  width: 50%;
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word;      /* IE */
}
.colorTodo1 {
  background: linear-gradient(
      90deg,
      rgba(93, 12, 255, 1) 0%,
      rgba(155, 0, 250, 1) 100%
  );
}

.colorTodo2 {
  background: linear-gradient(
      90deg,
      rgba(255, 12, 241, 1) 0%,
      rgba(250, 0, 135, 1) 100%
  );
}
.colorTodo3 {
  background: linear-gradient(
      90deg,
      rgba(255, 118, 20, 1) 0%,
      rgba(255, 84, 17, 1) 100%
  );
}
.colorTodo4 {
  background: linear-gradient(
      90deg,
      rgba(20, 159, 255, 1) 0%,
      rgba(17, 122, 255, 1) 100%
  );
}
.todo-rows-list{
  width: 100%;
}
.todo-icons {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: #fff !important;
}



.Mobile_wrapper {
  max-width: 800px;
  min-width: 500px;
  margin: 0 auto;
}

.Mobile_iphone {
  position: relative;
  background: #202020;
  width: 45%;
  height: 0;
  padding-top: 95%;
  margin: 0 auto;
  border-radius: 3em;
}

.Mobile_power {
  position: absolute;
  background: #202020;
  width: 13%;
  height: .5%;
  top: -.5%;
  right: 15%;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
}

.Mobile_lock {
  position: absolute;
  background: #202020;
  width: 1%;
  height: 5%;
  top: 12.5%;
  left: -1%;
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
}

.Mobile_volume {
  position: absolute;
  background: #202020;
  width: 1%;
  height: 4%;
  top: 23%;
  left: -1%;
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
}

.Mobile_volumedown{ top: 31%; }

.Mobile_camera {
  position: absolute;
  top: 4%;
  left: 48.5%;
  background: #3f3f3f;
  width: 3%;
  height: 0;
  padding-top: 3%;
  border-radius: 50%;
}

.Mobile_speaker {
  position: absolute;
  top: 7%;
  left: 42.5%;
  background: #3f3f3f;
  width: 15%;
  height: 0;
  padding-top: 3%;
  border-radius: 1em;
}

.Mobile_appleicon {
  padding-top: 25%;
  opacity: 0;
}

.Mobile_appleicon img{
  width: 100%;
  height: 100%;
}

.Mobile_text {
  opacity: 0;
  font-size: 1.25rem;
  text-align: center;
  opacity: 1
}

.Mobile_screen {
  background: #2f2f2f;
  width: 90%;
  height: 75%;
  position: absolute;
  top: 12.5%;
  left: 5%;
  background: #f7f7f7;
}

.Mobile_button {
  position: absolute;
  bottom: 2.75%;
  left: 42.5%;
  background: #3f3f3f;
  /* border: 1px solid #3f3f3f; */
  width: 15%;
  height: 0;
  padding-top: 15%;
  border-radius: 50%;
}

.Mobile_square {
  position: absolute;
  top: 27.5%;
  left: 27.5%;
  border: 2px solid #d7d7d7;
  border-radius: .3em;
  width: 45%;
  height: 45%;
}

/*------------------------------------------------------------------
[ Responsive ]*/
@media (max-width: 1300px) {
  .logo{
    width: 250px;
  }
  .Mobile_power{
    right: 20%;
  }
}
@media (max-width: 992px) {
  .wrap-login100 {
    min-width: 400px;
    padding: 50px 50px 50px 50px;
  }

  .login100-pic {
    width: 35%;
  }

  .login100-form {
    width: 50%;
  }
  .Mobile_power{
    right: 25%;
  }
}

@media (max-width: 768px) {
  .timer{
    display: none;
  }
  .wrap-login100 {
    min-width: 400px;
    padding: 50px 50px 50px 50px;
  }

  .login100-pic {
    display: none;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 476px) {
  .wrap-login100 {
    min-width: 200px;
    padding: 50px 15px 33px 15px;
  }
  .divlogo{
    display: none;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: Poppins-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}